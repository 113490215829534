import React, { Fragment, useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { connect } from "react-redux"
import * as actions from "@redux/actions"
import imageCompression from "browser-image-compression"

import { Button, RenderFormElement, Error, Success } from "@components/Form"
import Loader from "@components/Loader"
import { H2, H6 } from "@components/StyledElements"
import { FORM_QUESTIONS } from "./questions"

const Checkin = ({
  ClearErrorSuccess,
  SubmitClientCheckin,
  clientCheckinSuccess,
  clientCheckinError,
  loading,
}) => {
  const [files, setFiles] = useState([])
  const [imgCount, setImgCount] = useState(0)
  // - - - - - - - - - - - - - - - - - - - - -
  const [err, setErr] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const { register, handleSubmit, errors, watch, control, reset } = useForm()
  const watchAll = watch({ nest: true })

  useEffect(() => {
    ClearErrorSuccess()
  }, [])

  const onSubmit = async data => {
    try {
      setErr(null)
      setIsDisabled(true)
      const formData = new FormData()
      // attach data
      Object.keys(data).map(key => formData.append(key, data[key]))
      // attach images
      files.forEach(file => {
        formData.append("files[]", file[0], file[0].name)
      })
      //! Send formData to Redux to submit
      SubmitClientCheckin({ formData })
      setIsDisabled(false)
    } catch (error) {
      console.error(`Checking Error: ${error}`)
      setErr(
        `An error occurred and your information was not submitted. Please try again.`
      )
      setIsDisabled(false)
    }
  }

  useEffect(() => {
    if (clientCheckinSuccess) {
      reset()
    }
  }, [clientCheckinSuccess])

  const onFilesAdded = async (file, type) => {
    const { name } = file[0]
    const fileExt = name
      .split(".")
      .pop()
      .toLowerCase()
    if (fileExt === "jpg" || fileExt === "png" || fileExt === "jpeg") {
      //! Try to compress file prior to upload
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        const compressedFile = await imageCompression(file[0], options)
        setFiles([...files, [compressedFile]])
        setImgCount(imgCount + 1)
        setErr("")
      } catch (error) {
        console.error("comporession error: ", error)
        setFiles([...files, file])
        setImgCount(imgCount + 1)
        setErr("")
      }
    } else {
      setErr("Must be .jpg or .png image file")
    }
  }
  const onFileRemove = index => {
    const temp = []
    for (let i = 0; i < files.length; i++) {
      if (i !== index) {
        temp.push(files[i])
      }
    }
    setFiles(temp)
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-4">
          <H2>Checkin Questions</H2>
          <H6>
            <i>
              Update me First Thing Every Sunday morning, please see questons
              below.
            </i>
          </H6>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <div className="row">
          {FORM_QUESTIONS.map((item, i) => (
            <Fragment key={`${i}-checkin-question`}>
              {RenderFormElement(
                item,
                i,
                register,
                errors,
                onFilesAdded,
                onFileRemove,
                isDisabled,
                files,
                watchAll,
                control
              )}
            </Fragment>
          ))}
        </div>
        <div className="row d-flex justify-content-center">
          {loading ? (
            <div className="col-12 mt-2 text-center">
              <Loader
                message={
                  "Submission in progress - if you had a lot of images this can take a few moments"
                }
              />
            </div>
          ) : (
            <div className="col-12 mt-2 text-center">
              <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
                Submit Checkin
              </Button>
            </div>
          )}
          {clientCheckinSuccess && (
            <div className="col-12 mt-4 text-center">
              <Success message={clientCheckinSuccess} />
            </div>
          )}
          {clientCheckinError && (
            <div className="col-12 mt-4 text-center">
              <Error message={clientCheckinError} />
            </div>
          )}
          {err && (
            <div className="col-12 mt-4 text-center">
              <Error message={err} />
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    clientCheckinSuccess: state.auth.clientCheckinSuccess,
    clientCheckinError: state.auth.clientCheckinError,
  }
}
export default connect(mapStateToProps, actions)(Checkin)
