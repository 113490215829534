import React, { useEffect } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { connect } from "react-redux"
import * as actions from "@redux/actions"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import { Button } from "@components/Form"
import { H1, HR } from "@components/StyledElements"
import CheckIn from "@components/Checkin"

const CreateCheckinPage = ({ authenticated, loading, ReduxLogout }) => {
  const data = useStaticQuery(graphql`
    query CreateCheckinPageQuery {
      banner: allFile(filter: { name: { eq: "client_portal_1440x400" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!authenticated) {
      ReduxLogout()
    }
  }, [authenticated])

  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <SEO title="Client Profile | Create Checkin" />
      <div className="container-fluid">
        <div className="row d-flex justify-content-center mt-5">
          <H1>Create Checkin</H1>
        </div>
        <div className="row mt-2">
          <CheckIn />
        </div>
        <div className="col-md-8 mx-auto">
          <HR />
        </div>
        <div className="row justify-content-center mt-2">
          <Button onClick={() => navigate("/app/profile")}>Go Back</Button>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    loading: state.auth.loading,
  }
}
export default connect(mapStateToProps, actions)(CreateCheckinPage)
