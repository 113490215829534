export const FORM_QUESTIONS = [
  {
    question: `Your morning body weight. PLEASE LIST THIS IN LBS Not KG.`,
    placeholder: `Paste log here`,
    name: `weight_log`,
    type: `textarea`,
    required: `Please paste your daily weight log`,
    fullwidth: true,
  },
  {
    question: `What are your pumps like, do they hold through out your workouts or fade?`,
    placeholder: `Please describe`,
    name: `pumps_status`,
    type: `textarea`,
    required: `Please describe what your pumps are like`,
    fullwidth: true,
  },
  {
    question: `What is your muscle soreness like for a days after the workout?`,
    placeholder: `Please describe`,
    name: `recovery_status`,
    type: `textarea`,
    required: `Please describe your muscle soreness`,
    fullwidth: true,
  },
  {
    question: `What is your sleep like?`,
    placeholder: `Please describe`,
    name: `sleep_status`,
    type: `textarea`,
    required: `Pleas describe your sleep`,
    fullwidth: true,
  },
  {
    question: `You need to send me pictures every Sunday in the same place/lighting. Upload at least 3x images.`,
    name: `pictures`,
    type: `file`,
    required: `Please attach at least 3x images`,
    fullwidth: true,
  },
  {
    question: `What is your strength like?`,
    placeholder: `Please describe`,
    name: `strength_status`,
    type: `textarea`,
    required: `Please describe your strength`,
    fullwidth: true,
  },
  {
    question: `What week have you just completed in your program, e.g. 1-16 weeks?`,
    placeholder: `Specify week`,
    name: `current_week`,
    type: `textarea`,
    required: `Please specify`,
    fullwidth: true,
  },
  {
    question: `Have you 100% kept to the program in ALL area’s that I have designed for you?`,
    placeholder: `Yes or No`,
    name: `program_status`,
    type: `textarea`,
    required: `Have you 100% kept to program?`,
    fullwidth: true,
  },
  {
    question: `What has your external stress levels been like during the past week, eg work, home, life etc?`,
    placeholder: `Please describe`,
    name: `stress_status`,
    type: `textarea`,
    required: `Please describe your external stress levels`,
    fullwidth: true,
  },
  {
    question: `What has your hunger and appetite been like?`,
    placeholder: `Please describe`,
    name: `hunger_status`,
    type: `textarea`,
    required: `Please desribe your hunger and appetite`,
    fullwidth: true,
  },
  {
    question: `Do you feel you are gaining body fat, loosing body fat or staying stable with your body fat?`,
    placeholder: `Please explain`,
    name: `bodyfat_status`,
    type: `textarea`,
    required: `Please explain your thoughts on your current body fat`,
    fullwidth: true,
  },
  {
    question: `If you are competing or have a photo shoot, please give clear clarity on how many weeks and days until the event.`,
    placeholder: `Weeks/days`,
    name: `competing_status`,
    type: `textarea`,
    required: false,
    fullwidth: true,
  },
]
